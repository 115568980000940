import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, IconButton, MenuItem, Select, AppBar, Toolbar, Paper } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { RemoveCircle } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


const ImageUpload = () => {
  const [images, setImages] = useState([]);
  const [captions, setCaptions] = useState({});
  const [years, setYears] = useState({});
  const [processingMessage, setProcessingMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false); // State to track loading state

  // Debounce function
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedHandleCaptionChange = debounce((index, caption) => {
    setCaptions((prevCaptions) => ({ ...prevCaptions, [index]: caption }));
  }, 500); // Adjust the delay as needed

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = [...images, ...files];
    setImages(newImages);
  };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    // Remove captions and years for the deleted image
    const newCaptions = { ...captions };
    delete newCaptions[index];
    setCaptions(newCaptions);

    const newYears = { ...years };
    delete newYears[index];
    setYears(newYears);
  };

  const handleYearChange = (index, year) => {
    setYears({ ...years, [index]: year });
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading state to true when submitting
    setProcessingMessage('Deine Bilder werden hochgeladen...');
    setAlertSeverity('info');
    setShowAlert(true);

    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append('images', image);
      formData.append(`captions_${index}`, captions[index] || ''); // Append unique caption keys
      formData.append(`years_${index}`, years[index] || ''); // Append unique year keys
    });

    try {
      const response = await fetch('/upload', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setProcessingMessage('Danke, das hat gekplappt! Lad doch noch was hoch!');
      setAlertSeverity('success');
      setImages([]); // Clear the images array after successful upload
      setCaptions({}); // Clear captions after successful upload
      setYears({}); // Clear years after successful upload
    } catch (error) {
      console.error('Error:', error);
      setProcessingMessage('Das hat leider nicht geklappt, sorry! Versuch es nochmal!');
      setAlertSeverity('error');
    } finally {
      setLoading(false); // Set loading state back to false when done processing
    }
  };

  useEffect(() => {
    let timer;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
        setProcessingMessage(null);
      }, 15000);
    }
    return () => clearTimeout(timer);
  }, [showAlert]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setImages(files);
  };

  return (
    <Box sx={{ flexGrow: 1, pt: 8 }}>
        <AppBar position="fixed" sx={{ p: 0 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Hochzeits-Bildersammler
          </Typography>
        </Toolbar>
      </AppBar>
    <Box sx={{ padding: 2, maxWidth: 600, margin: '0 auto', alignContent: 'center'}}>
    <Paper 
        elevation={0} 
        sx={{ 
            border: '2px solid #f5f5f5',
            borderRadius: '20px',
            padding: '10px',
            textAlign: 'center',
            marginBottom: '20px',
            }}
        >
        <Typography variant="body">
        Jana und Felix heiraten! 🎉 Am Hochzeitstag möchten wir eine Diashow mit Bildern aus ihrem Leben zeigen. 
        Also beteilige dich und lade deine schönsten / lustigsten Bilder (Formate: png, jpg, jpeg) von Jana und / oder Felix hoch!
        Wir würden uns freuen, wenn du auch eine kleine Geschichte zu deinen Bildern schreibst und uns sagst, wann die Bilder ca. aufgenommen wurden.
        Wenn du mehrere Bilder hochladen möchtest, ziehe sie einfach in das Feld unten oder klicke auf "Auswählen".
        Solltest du Fragen haben oder Hilfe benötigen, melde dich einfach über WhatsApp bei einer der Telefonnummern auf der Einladung.
        Danke für deine Unterstützung! 🙏
        </Typography>
    </Paper>
      {showAlert && (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setShowAlert(false)}
          severity={alertSeverity}
          style={{ marginTop: 10, marginBottom: 10}}
        >
          {processingMessage}
        </MuiAlert>
      )}
      {images.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          {images.map((image, index) => (
            <Box key={index} sx={{ mb: 2, textAlign: 'center', boxShadow: 4, p: 2, borderRadius: 2, position: 'relative' }}>
              <IconButton
                sx={{ 
                  position: 'absolute', 
                  top: -15, 
                  right: -15, 
                  bgcolor: 'red', 
                  width: 40, 
                  height: 40 ,
                  color: 'white',
                  // On hover effect
                  '&:hover': {
                    bgcolor: 'red'
                  }
              }}
                onClick={() => handleDeleteImage(index)}
              >
                <RemoveCircle />
              </IconButton>
              <img
                src={URL.createObjectURL(image)}
                alt={`preview ${index}`}
                style={{ maxWidth: '100%', height: 'auto' }}
              />
              <TextField
                label={"Schreib' was Nettes!"} // Add index to label to differentiate captions
                variant="outlined"
                fullWidth
                multiline  // Enable multiline
                rowsMax={4} // Maximum number of rows before scrolling
                margin="normal"
                onChange={(e) => debouncedHandleCaptionChange(index, e.target.value)}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
              <Typography sx={{ mr: 2 }}>Wann war das ungefähr?</Typography>
              <Select
                value={years[index] || ''}
                sx={{ width: 100 }}
                onChange={(e) => handleYearChange(index, e.target.value)}
              >
                {/* Generate years from current year to 100 years ago */}
                {[...Array(32).keys()].map((_, i) => {
                  const year = new Date().getFullYear() - i;
                  return <MenuItem key={year} value={year}>{year}</MenuItem>
                })}
              </Select>
              </Box>
            </Box>
          ))}
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{borderRadius: '20px'}}>
            {loading ? <CircularProgress size={24} sx={{color: 'white'}}/> : 'Abschicken'}
          </Button>
        </Box>
      )}
      <Box
        sx={{
          borderRadius: '20px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          boxShadow: 6,
          mt: images.length > 0 ? 2 : 0 // Add margin top only if images are uploaded
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Typography>Drag and Drop</Typography>
        <Typography>oder</Typography>
        <label htmlFor="upload-button">
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{ borderRadius: '20px'}}
            startIcon={<AddCircleOutlineIcon />}
          >
            Auswählen
          </Button>
        </label>
        <input
          type="file"
          multiple
          accept=".jpg, .png, .jpeg"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id="upload-button"
        />
      </Box>
      <Box sx={{ padding: 2, marginTop: 10, alignContent: 'center', textAlign: 'center'}}>
        <Button
            variant="outlined"
            color="primary"
            startIcon={<MailOutlineIcon />}
            onClick={() => window.location.href = 'mailto:m.striethkalthoff@icloud.com'}
        >
            Schreib uns!
        </Button>
        <Typography variant="body1" color={'textSecondary'} sx={{ mt: 2 }}>
            Made with ❤️ and AI
        </Typography>
    </Box>
    </Box>
    </Box>
  );
};

export default ImageUpload;
